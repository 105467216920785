import React, { FC, PropsWithChildren } from 'react';

import Template from '~/components/Template';
import menu from '~/constants/menu/espace';
import { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

interface TemplateEspaceProps {
  pathname?: string;
}

const TemplateEspace: FC<
  PropsWithChildren<TemplateEspaceProps & EspaceProps & UserProps>
> = ({ children, espace, pathname, user }) => (
  <Template
    espaceId={espace.id}
    kind="espace"
    menu={menu}
    pathname={pathname}
    showNavigation
    user={user}
  >
    {children}
  </Template>
);
export default TemplateEspace;
