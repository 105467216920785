import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { ViewProps } from '~/components/View/props';

const ViewSplit: FC<PropsWithChildren<ViewProps>> = ({
  className,
  children,
}) => (
  <div
    className={classnames(
      'cms-view-split flex space-x-6 md:mx-auto md:px-6',
      className,
    )}
  >
    {children}
  </div>
);

export default ViewSplit;
