import { DocumentType, EspaceType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import SplashScreen from '~/components/SplashScreen';
import { ReducersProps } from '~/reducers';

import { UserProps } from './requireUser';

export interface EspaceProps {
  espace: DocumentType<EspaceType>;
}

function requireEspace<
  P extends {
    location: { href: string; pathname: string };
    params: Record<string, string>;
  },
>(ComposedComponent: FC<P>): FC<P & EspaceProps & UserProps> {
  return (props => {
    const {
      params: { espaceId },
      location: { pathname },
    } = props;
    const { espace, espaceIsLoading, user, userIsLoading } = useSelector(
      (globalState: ReducersProps) => globalState.cms,
    );

    if (userIsLoading || espaceIsLoading) {
      return <SplashScreen pathname={pathname} />;
    }

    if (!user) {
      return <div>utilisateur non connecté</div>;
    }

    if (!espaceId || !espace) {
      return <div>L&apos;espace n&apos;est pas défini</div>;
    }

    const hasNotAccess =
      !user.espaceIds ||
      0 === user.espaceIds.length ||
      !user.espaceIds.includes(espaceId);

    if (user.type !== 'admin' && hasNotAccess) {
      return <div>Vous n&apos;avez pas accès a cette espace</div>;
    }

    if (userIsLoading) {
      return <div>Chargement en cours de l&apos;utilisateur</div>;
    }

    // if (
    //   !user.type ||
    //   !['admin', 'owner', 'editor', 'professional'].includes(user.type)
    // ) {
    //   return (
    //     <div className="flex h-screen items-center justify-center">
    //       <div className="flex max-w-md flex-col items-center space-y-3">
    //         <span>
    //           L&apos;utilisateur doit être connecté ou avoir les autorisations
    //         </span>
    //         <Login href={href} />
    //       </div>
    //     </div>
    //   );
    // }

    return <ComposedComponent {...(props as P)} espace={espace} user={user} />;
  }) as FC<P & EspaceProps & UserProps>;
}

export default requireEspace;
